<template>
	<div class="pageContainer">
		
		<index :type="type" @setType="settype"></index>

	</div>
</template>

<script>
import Index from '@/components/data/index.vue';
export default {
	name: 'FrontendPcBigdataPrepare',
	components: { Index },
	data() {
		return {
			type: 3, //1代表 拟上市  2正在公告  3 成交
		}
	},
	onLoad() { 
		this.mode = 'map'
	},

	methods:{
		settype(settype){
			this.type = settype
		}
	},
	computed: {
		mode: {
			get() {
				// console.log('get',this.$store.state.mapmode)
				return this.$store.state.mapmode
			},
			set(v) {
				this.$store.commit('mapmode', v)
			}

		}



	},

};
</script>

<style lang="scss" scoped>
.pageContainer {
	width: 100%;
	overflow-y: scroll;
}
</style>